import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument} from 'angularfire2/firestore'
import { snapshotChanges } from 'angularfire2/database';


@Injectable({
  providedIn: 'root'
})
export class CategoriasService {

  public collection: any;
  public document: any;

  constructor(
    
    private firestore: AngularFirestore

  ) {}

  getResultados(categoria: string){

    return this.firestore.collection(categoria).snapshotChanges();
  }
}
