import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { CategoriasService } from '../../services/categorias.service';

@Component({
  selector: 'app-cat-j',
  templateUrl: './cat-j.component.html',
  styleUrls: ['./cat-j.component.scss'],
})
export class CatJComponent implements OnInit {

  public splashImage: boolean = true;

  resultadosV: any = [];
  resultadosF: any = [];
  detalles: any = [];
  detallesLive : any = [];
 
  player1V: any = [];
  player2V: any  = []
  player3V: any = [];

  player1F: any = [];
  player2F: any = [];
  player3F: any = [];


  categoria: string = 'cat-j';
  categoriaF: string = 'cat-jF';
  categoriaDetalles: string = 'detalles-j';

  imageInterval: any;
  timeLeft: any;

  reset: string;

  constructor(
    private categoriasService: CategoriasService,
    private router: Router, 
    private zone: NgZone,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.route.queryParams
    .subscribe(params => {
      this.reset = params.reset;
      if(this.reset == 'timer'){

        this.splashImage = true;

        this.imageInterval = setTimeout( ()=> {
           this.splashImage = false
         }, 2000)
     
        this.timeLeft = setTimeout( ()=> {
           this.zone.run(() => this.router.navigate(['../cat-k/'],{ queryParams: { reset: 'timer' }}));
         }, 10000)

      }
    });

   this.imageInterval = setTimeout( ()=> {
      this.splashImage = false
    }, 2000)

   this.timeLeft = setTimeout( ()=> {
      this.zone.run(() => this.router.navigate(['../cat-k/']));
    }, 10000)

    //Categoria Varonil
    this.categoriasService.getResultados(this.categoria).subscribe(
      actionArray => {
        console.log(actionArray)
      this.resultadosV = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        } as any;
      })

      this.player1V = this.resultadosV[0];
      this.player2V = this.resultadosV[1];
      this.player3V = this.resultadosV[2];
     
    
    });

      //Categoria Femenil
      this.categoriasService.getResultados(this.categoriaF).subscribe(
        actionArray => {
        this.resultadosF = actionArray.map(item => {
          return {
            id: item.payload.doc.id,
            ...item.payload.doc.data()
          } as any;
        })
  
        this.player1F = this.resultadosF[0];
        this.player2F = this.resultadosF[1];
        this.player3F = this.resultadosF[2];
      
  
      });


    //Detalles
    this.categoriasService.getResultados(this.categoriaDetalles).subscribe(
      actionArray => {
      this.detalles = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        } as any;       
      })
      this.detallesLive = this.detalles[0];
    
    });

   
   }

}
