import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

//Componentes
import { CatAComponent } from './components/cat-a/cat-a.component';
import { CatBComponent } from './components/cat-b/cat-b.component';
import { CatCComponent } from './components/cat-c/cat-c.component';
import { CatDComponent } from './components/cat-d/cat-d.component';
import { CatEComponent } from './components/cat-e/cat-e.component';
import { CatFComponent } from './components/cat-f/cat-f.component';
import { CatGComponent } from './components/cat-g/cat-g.component';
import { CatHComponent } from './components/cat-h/cat-h.component';
import { CatIComponent } from './components/cat-i/cat-i.component';
import { CatJComponent } from './components/cat-j/cat-j.component';
import { CatKComponent } from './components/cat-k/cat-k.component';
import { CatRAComponent } from './components/cat-ra/cat-ra.component';
import { CatRBComponent } from './components/cat-rb/cat-rb.component';
import { CatRcComponent } from './components/cat-rc/cat-rc.component';
import { CatRdComponent } from './components/cat-rd/cat-rd.component';
import { CatGENComponent } from './components/cat-gen/cat-gen.component';


const routes: Routes = [
  { 
  path: '', 
  redirectTo: 'cat-a', 
  pathMatch: 'full' 
  },

  {
   path: 'cat-a',
   component: CatAComponent,
   pathMatch: 'full'
  
  },
  {
    path: 'cat-b',
    component: CatBComponent,
    pathMatch: 'full'
   
   },
   {
    path: 'cat-c',
    component: CatCComponent,
    pathMatch: 'full'
   
   },

   {
    path: 'cat-d',
    component: CatDComponent,
    pathMatch: 'full'
   
   },

   {
    path: 'cat-e',
    component: CatEComponent,
    pathMatch: 'full'
   
   },

   {
    path: 'cat-f',
    component: CatFComponent,
    pathMatch: 'full'
   
   },
   {
   path: 'cat-g',
    component: CatGComponent,
    pathMatch: 'full'
   
   },
   {
    path: 'cat-h',
     component: CatHComponent,
     pathMatch: 'full'
    
    },
   {
    path: 'cat-i',
     component: CatIComponent,
     pathMatch: 'full'
    
    },
    {
      path: 'cat-j',
       component: CatJComponent,
       pathMatch: 'full'
      
    },
    {
      path: 'cat-k',
       component: CatKComponent,
       pathMatch: 'full'
      
    },
    {
      path: 'cat-ra',
       component: CatRAComponent,
       pathMatch: 'full'
      
      },

      {
        path: 'cat-rb',
         component: CatRBComponent,
         pathMatch: 'full'
        
        },
        {
          path: 'cat-rc',
           component: CatRcComponent,
           pathMatch: 'full'
          
          },
          {
            path: 'cat-rd',
             component: CatRdComponent,
             pathMatch: 'full'
            
            },

        {
          path: 'cat-gen',
           component: CatGENComponent,
           pathMatch: 'full'
          
          },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
