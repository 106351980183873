import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { CategoriasService } from '../../services/categorias.service';

@Component({
  selector: 'app-cat-d',
  templateUrl: './cat-d.component.html',
  styleUrls: ['./cat-d.component.scss'],
})
export class CatDComponent implements OnInit {

  public splashImage: boolean = true;

  resultadosV: any = [];
  resultadosF: any = [];
  detalles: any = [];
  detallesLive : any = [];
 
  player1V: any = [];
  player2V: any  = []
  player3V: any = [];
  player4V: any = [];
  player5V: any  = []
  player6V: any = [];
  

  player1F: any = [];
  player2F: any = [];
  player3F: any = [];
  player4F: any = [];
  player5F: any = [];
  player6F: any = [];


  categoria: string = 'cat-d';
  categoriaF: string = 'cat-dF';
  categoriaDetalles: string = 'detalles-d';

  imageInterval: any;
  timeLeft: any;

  reset: string;

  constructor(
    private categoriasService: CategoriasService,
    private router: Router, 
    private zone: NgZone,
    private route: ActivatedRoute
  ) {  }


  ngOnInit() {

    this.route.queryParams
    .subscribe(params => {
      this.reset = params.reset;
      if(this.reset == 'timer'){

        this.splashImage = true;

        this.imageInterval = setTimeout( ()=> {
         this.splashImage = false
         }, 2000)
     
        this.timeLeft = setTimeout( ()=> {
        this.zone.run(() => this.router.navigate(['../cat-e/'],{ queryParams: { reset: 'timer' }}));
         }, 10000)

      }
    });

   this.imageInterval = setTimeout( ()=> {
     this.splashImage = false
    }, 2000)

   this.timeLeft = setTimeout( ()=> {
     this.zone.run(() => this.router.navigate(['../cat-e/']));
    }, 10000)

    //Categoria Varonil
    this.categoriasService.getResultados(this.categoria).subscribe(
      actionArray => {
      this.resultadosV = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        } as any;
      })

      this.player1V = this.resultadosV[0];
      this.player2V = this.resultadosV[1];
      this.player3V = this.resultadosV[2];
      this.player4V = this.resultadosV[3];
      this.player5V = this.resultadosV[4];
      this.player6V = this.resultadosV[5];
     
    
    });

    //Categoria Femenil
    this.categoriasService.getResultados(this.categoriaF).subscribe(
      actionArray => {
      this.resultadosF = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        } as any;
      })

      this.player1F = this.resultadosF[0];
      this.player2F = this.resultadosF[1];
      this.player3F = this.resultadosF[2];
      this.player4F = this.resultadosF[3];
      this.player5F = this.resultadosF[4];
      this.player6F = this.resultadosF[5];
    

    });

    //Detalles
    this.categoriasService.getResultados(this.categoriaDetalles).subscribe(
      actionArray => {
      this.detalles = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        } as any;       
      })
      this.detallesLive = this.detalles[0];
    
    });

   
   }

}
