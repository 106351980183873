import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';

//Firebase
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestore} from 'angularfire2/firestore';
import { environment } from '../environments/environment';


//Componentes
import { CatAComponent } from './components/cat-a/cat-a.component';
import { CatBComponent } from './components/cat-b/cat-b.component';
import { CatCComponent } from './components/cat-c/cat-c.component';
import { CatDComponent } from './components/cat-d/cat-d.component';
import { CatEComponent } from './components/cat-e/cat-e.component';
import { CatFComponent } from './components/cat-f/cat-f.component';
import { CatGComponent } from './components/cat-g/cat-g.component';
import { CatHComponent } from './components/cat-h/cat-h.component';
import { CatIComponent } from './components/cat-i/cat-i.component';
import { CatJComponent } from './components/cat-j/cat-j.component';
import { CatKComponent } from './components/cat-k/cat-k.component';
import { CatRAComponent } from './components/cat-ra/cat-ra.component';
import { CatRBComponent } from './components/cat-rb/cat-rb.component';
import { CatRcComponent } from './components/cat-rc/cat-rc.component';
import { CatRdComponent } from './components/cat-rd/cat-rd.component';
import { CatGENComponent } from './components/cat-gen/cat-gen.component';


//Services
import { CategoriasService } from './services/categorias.service'

@NgModule({
  declarations: [
    AppComponent,
    CatAComponent,
    CatBComponent,
    CatCComponent,
    CatDComponent,
    CatEComponent,
    CatFComponent,
    CatGComponent,
    CatHComponent,
    CatIComponent,
    CatJComponent,
    CatKComponent,
    CatRAComponent,
    CatRBComponent,
    CatRcComponent,
    CatRdComponent,
    CatGENComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(),
     AppRoutingModule, 
     AngularFireModule.initializeApp(environment.firebase),
     AngularFireDatabaseModule,
     ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })],
  providers: [
    StatusBar,
    SplashScreen,
    CategoriasService,
    AngularFirestore,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
