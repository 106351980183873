import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { CategoriasService } from '../../services/categorias.service';

@Component({
  selector: 'app-cat-h',
  templateUrl: './cat-h.component.html',
  styleUrls: ['./cat-h.component.scss'],
})
export class CatHComponent implements OnInit {

  public splashImage: boolean = true;

  resultadosV: any = [];
  resultadosF: any = [];
  detalles: any = [];
  detallesLive : any = [];
 
  player1V: any = [];
  player2V: any  = []
  player3V: any = [];
  

  player1F: any = [];
  player2F: any = [];
  player3F: any = [];


  categoria: string = 'cat-h';
  categoriaF: string = 'cat-hF';
  categoriaDetalles: string = 'detalles-h';

  imageInterval: any;
  timeLeft: any;

  reset: string;

  constructor(
    private categoriasService: CategoriasService,
    private router: Router, 
    private zone: NgZone,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.route.queryParams
    .subscribe(params => {
      this.reset = params.reset;
      if(this.reset == 'timer'){

        this.splashImage = true;

        this.imageInterval = setTimeout( ()=> {
          this.splashImage = false
         }, 2000)
     
        this.timeLeft = setTimeout( ()=> {
          this.zone.run(() => this.router.navigate(['../cat-i/'],{ queryParams: { reset: 'timer' }}));
         }, 10000)

      }
    });

   this.imageInterval = setTimeout( ()=> {
      this.splashImage = false
    }, 2000)

   this.timeLeft = setTimeout( ()=> {
     this.zone.run(() => this.router.navigate(['../cat-i/']));
    }, 10000)

    //Categoria Varonil
    this.categoriasService.getResultados(this.categoria).subscribe(
      actionArray => {
      this.resultadosV = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        } as any;
      })

      this.player1V = this.resultadosV[0];
      this.player2V = this.resultadosV[1];
      this.player3V = this.resultadosV[2];
     
    
    });

    //Categoria Femenil
    this.categoriasService.getResultados(this.categoriaF).subscribe(
      actionArray => {
      this.resultadosF = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        } as any;
      })

      this.player1F = this.resultadosF[0];
      this.player2F = this.resultadosF[1];
      this.player3F = this.resultadosF[2];
    

    });

    //Detalles
    this.categoriasService.getResultados(this.categoriaDetalles).subscribe(
      actionArray => {
      this.detalles = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        } as any;       
      })
      this.detallesLive = this.detalles[0];
    
    });

   
   }

}
